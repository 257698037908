import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle, css } from 'styled-components';

const Css = createGlobalStyle`
  :root, :host {
    all: unset;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  :is(sup) {
    font-size: 0.625rem !important;
    vertical-align: super !important;
    white-space: nowrap !important;
  }
`

export const shadowOuterStyle = css`
  all: unset;
  line-height: 1;
  font-family: var(--ja-font);
  letter-spacing: 0;
`

export const RootCss: FunctionComponent = () => {
  return <>
    <Css />
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css" />
  </>
}